import React, { FC } from 'react'
import AboutPhoto from '../assets/aboutPhoto.webp'
import GetStartedButton from '../components/GetStartedButton'
interface AboutProps {
    setModalOpen: (isOpen: boolean) => void;
}
const About: FC<AboutProps> = ({setModalOpen}) => {
    return (
        <div className='flex flex-col md:justify-center lg:items-center mt-20 lg:px-0 px-20 w-full'>
            <div className='heading text-[26px] lg:w-[750px] text-center'>
                Getting that day off after a long week and don’t know what to watch? Binge has got you covered
            </div>
            <div className='lg:flex justify-between items-center mt-10 w-full md:px-10 2xl:px-96 3xl:px-[800px] xl:px-44 '>
                <div className='2xl:w-full'>
                    <img src={AboutPhoto} alt='Hello' className='rounded-2xl h-[430px] lg:block hidden' />
                </div>
                <div className='lg:block flex flex-col justify-center items-center '>
                    <div className='bodyText text-[22px] lg:w-96'>
                        Trained on a dataset of over a million films, binge will ask you a series a questions and will give you movie recommndations based on your vibe and preferrences.
                    </div>
                    <GetStartedButton onClick={() => { setModalOpen(true) }} />
                </div>
            </div>
        </div>
    )
}

export default About