import React, { useEffect, useState } from 'react';
import Step1 from './ModalContent/Step1';
import GenreSelection from './ModalContent/GenreSelection';
import EraSelection from './ModalContent/EraSelection';
import TypeSelection from './ModalContent/TypeSelection';
import SuggestionsComponent from './ModalContent/SuggestionsComponent';
import { ApiResponse, MovieSuggestion, PostData } from '../types';
import axios from 'axios';
import { postData } from '../api/apiService';
import { handlePostData } from '../utils';

interface ImageModalProps {
    isOpen: boolean;
    onClose: () => void;
    backgroundImage: string; // Path to the background image
    steps: number;
    setSteps: (steps: number) => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ isOpen, onClose, backgroundImage, setSteps, steps }) => {
    const [likedMovie, setLikedMovie] = useState('');
    const [likedGenre, setLikedGenre] = useState('');
    const [likedDirector, setLikedDirector] = useState('');
    const [suggestions, setSuggestions] = useState<MovieSuggestion[]>([]);
    const [era, setEra] = useState([
        { name: 'Pre 1970s', checked: false },
        { name: '1970-2000', checked: false },
        { name: '2000 Onwards', checked: false },
    ]);
    const [restrictions, setRestrictions] = useState([
        { name: 'Nudity/Sexual Content', checked: false },
        { name: 'Suicide', checked: false },
        { name: 'Violence/Graphic Content', checked: false },
        { name: 'None', checked: true }, // Default to None
    ]);
    const [genres, setGenres] = useState([
        { name: 'Thriller', checked: false },
        { name: 'Horror', checked: false },
        { name: 'Comedy', checked: false },
        { name: 'Romcom', checked: false },
        { name: 'Action', checked: false },
        { name: 'Other', checked: false },
    ]);
    const [types, setTypes] = useState([
        { name: 'Uplifting/Inspiring', checked: false },
        { name: 'Mysterious/Intriguing', checked: false },
        { name: 'Gritty/Raw', checked: false },
        { name: 'Heartfelt/Emotional', checked: false },
        { name: 'Whimsical/Eccentric', checked: false },
        { name: 'Empowering/Action-Packed', checked: false },
    ]);

    const samplePostData = {
        previousMovie: "The Dark Knight",
        genres: ["Action", "Adventure", "Sci-Fi"],
        actors: "",
        era: "2000s",
        kind: ["uplifting", "dark", "action-packed"],
        viewingRestrictions: true
    };

    const data: PostData = {
        previousMovie: likedMovie,
        genres: genres.filter(g => g.checked).map(g => g.name),
        actors: likedDirector,
        era: era.find(e => e.checked)?.name || '',
        kind: types.filter(t => t.checked).map(t => t.name),
        viewingRestrictions: restrictions.some(r => r.name !== 'None' && r.checked)
    };

    // const handlePostData = async () => {
    //     axios.post('https://d3py3uwdde7sanxolbrpc3x)


    //     try {
    //         const result = await axios.request(options);
    //         setSuggestions(result.data);
    //     } catch (error: any) {
    //         console.error('Error posting data:', error);
    //         alert('Failed to fetch suggestions. Please check your connection and try again.'); // Improved user feedback
    //     }
    // };





    const handleNextClick = async () => {
        if (steps === 6) {
            await handlePostData({
                data: data,
                setSuggestions: setSuggestions,
            });
            setSteps(steps + 1);
        } else if (steps >= 7) {
            onClose(); // Close the modal if already at the last step
        } else {
            setSteps(steps + 1); // Move to the next step
        }
    };

    const handleClose = () => {
        setSteps(1);
        onClose();
    };

    useEffect(() => {
        console.log(suggestions)
    }, [suggestions])
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div style={{ backgroundImage: `url(${backgroundImage})` }} className="bg-cover bg-center w-full h-full flex flex-col justify-center items-center">
                <div className={`text-white absolute top-0 left-0 p-20 heading text-[22px] ${steps === 7 && 'hidden'}`}>Item {steps} of 6</div>
                <div className={`${steps !== 7 ? 'bg-[#f1ecec] shadow-lg lg:max-w-xl md:max-w-md max-w-[340px] lg:mt-0 mt-32' : 'bg-transparent'} p-8 rounded-3xl w-full`}>
                    {/* Conditional rendering based on current step */}
                    {steps === 1 ? (
                        <Step1 title={"What was the last movie you watched that you liked?"} setFieldValue={setLikedMovie} />
                    ) : steps === 2 ? (
                        <GenreSelection genres={genres} onChange={setGenres} />
                    ) : steps === 3 ? (
                        <Step1 title={"Any particular actors/directors/producers you’re a fan of?"} setFieldValue={setLikedDirector} />
                    ) : steps === 4 ? (
                        <EraSelection title='Any particular era of films that you’re interested in?' era={era} onChange={setEra} />
                    ) : steps === 5 ? (
                        <TypeSelection genres={types} onChange={setTypes} />
                    ) : steps === 6 ? (
                        <EraSelection title={'Any viewing restrictions?'} era={restrictions} onChange={setRestrictions} />
                    ) : steps === 7 ? (
                        <SuggestionsComponent onClose={handleClose} suggestions={suggestions} setSuggestions={setSuggestions} postData = {data} />
                    ) : null}
                </div>
                {steps !== 7 && (
                    <div className='text-black bg-[#f1ecec] px-20 py-3 flex justify-center items-center heading cursor-pointer text-[22px] rounded-full mt-10' onClick={handleNextClick}>Next</div>
                )}
            </div>
        </div>
    );
};

export default ImageModal;
