import { FC } from 'react'

interface GetStartedButtonProps {
  onClick: () => void
}

const GetStartedButton: FC<GetStartedButtonProps> = ({ onClick }) => {
  return <div onClick={onClick} className='rounded-full bg-red-primary text-white w-fit px-7 py-2 cursor-pointer mt-5 bodyTextBold text-[22px]'>Get Started</div>
}

export default GetStartedButton