// apiService.ts
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

interface APIResponse<T> {
  data: T;
}

const api: AxiosInstance = axios.create({
  baseURL: 'https://4qq9bjnwpk.execute-api.ap-southeast-1.amazonaws.com/BingeAI/*',
  headers: {
    'Content-Type': 'application/json',
  },
});

const postData = async <T>(
  endpoint: string,
  data: unknown,
  additionalHeaders: Record<string, string> = {}
): Promise<APIResponse<T>> => {
  try {
    const config: AxiosRequestConfig = {
      headers: {
        ...additionalHeaders,
      },
    };

    const response = await api.post<T>(endpoint, data, config);
    return {
      data: response.data,
    };
  } catch (error) {
    console.error('API POST Error:', error);
    throw error;
  }
};

export { postData };
