import React from 'react'
import { Adsense } from '@ctrl/react-adsense';
// import './css/AdsenseStylesheet.css';

function AdsenseComponent() {
    //console.log("Ads Shown")
    return (
        <Adsense
            className='ExampleAdSlot'
            client='ca-pub-9316329952743245' slot='1578963830'
            style={{ width: 500, height: 300 }}
            adTest='on' //Dev Only
        />
    )

}

export default AdsenseComponent