import React, { useState } from 'react';
import { FC } from 'react';

interface RateUsModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const RateUsModal: FC<RateUsModalProps> = ({ isOpen, onClose }) => {
    const [rating, setRating] = useState<number | null>(null);

    if (!isOpen) return null;

    const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const Star = ({ filled }: { filled: boolean }) => {
        return (
            <svg
                className={filled ? 'text-yellow-400' : 'text-gray-400'}
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M12 17.27L18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21 12 17.27Z" />
            </svg>
        );
    };

    return (
        <div className="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-50 flex justify-center items-center z-50" onClick={handleBackgroundClick}>
            <div className="bg-[#f1ecec] p-8 rounded-3xl shadow-lg lg:max-w-xl md:max-w-md max-w-sm w-full" onClick={(e) => e.stopPropagation()}>
                <div className="heading text-[54px] flex justify-center items-center">Rate Us</div>
                <div className="flex justify-center">
                    {[1, 2, 3, 4, 5].map((index) => (
                        <div key={index} onClick={() => setRating(index)} onMouseEnter={() => setRating(index)}>
                            <Star filled={index <= (rating || 0)} />
                        </div>
                    ))}
                </div>
                <div className="flex justify-center mt-4">
                <button onClick={onClose} className='rounded-full bg-red-primary text-white w-fit px-7 py-2 cursor-pointer mt-5 bodyTextBold text-[22px]'>
                        Done
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RateUsModal;
