import { postData } from "../api/apiService";
import { MovieSuggestion, PostData } from "../types";

interface handlePostDataParams {
    data: PostData;
    setSuggestions: (suggestions: MovieSuggestion[]) => void;
}
export const handlePostData = async ({data, setSuggestions}: handlePostDataParams) => {
    try {
      const endpoint = '';
      const result = (await postData<MovieSuggestion[]>(endpoint, data)).data;
      setSuggestions(result); // Assuming `setSuggestions` is defined in your component
      console.log(result);
    } catch (error: any) {
      console.error('Error posting data:', error.message);
    }
  };