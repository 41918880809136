// src/components/LoadingScreen.tsx
import React from 'react';
import loader from '../assets/profile.png';

const LoadingScreen: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="relative">
        <div className="absolute inset-0 m-auto animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-red-primary"></div>
        <img src={loader} alt="loader" className="h-32 w-32" />
      </div>
    </div>
  );
};

export default LoadingScreen;
