import { FC, useEffect } from 'react';
import Card from '../Card';
import { MdOutlineClose } from 'react-icons/md';
import { MovieSuggestion, PostData } from '../../types';
import { handlePostData } from '../../utils';

interface SuggestionsComponentProps { 
    onClose: ()=>void;
    suggestions: MovieSuggestion[];
    setSuggestions: (suggestions: MovieSuggestion[]) => void;
    postData: PostData;
}

const SuggestionsComponent: FC<SuggestionsComponentProps> = ({onClose, suggestions, setSuggestions, postData}) => {
    useEffect(() => {
        console.log(suggestions[0]);
    }, [suggestions]);
    return (
        <div className='relative lg:mt-0 lg:pb-20 lg:pt-10 overflow-y-auto h-screen lg:flex lg:justify-center lg:flex-col lg:items-center'>
            <div className={`absolute top-0 left-0 text-white lg:p-10 mr-10 w-full`}>
                <MdOutlineClose className='text-5xl' onClick={onClose} />
            </div>
            <div className='text-3xl lg:text-5xl text-white flex justify-center items-center py-5 heading mt-10'>Top Picks for you 🍿</div>
            <div className='flex flex-col lg:flex-row lg:justify-center items-center gap-y-10 lg:gap-x-10 p-5'>
                {suggestions.map((suggestion, index) => (
                    <Card key={index} imdbScore={parseInt(suggestion.imdb as string)} nameOfMovie={suggestion.title} posterUrl={suggestion.poster} tomatoMeterScore={parseInt(suggestion.rottentomatoes)}  />
                ))}
            </div>
            <div className='flex justify-center items-center pb-5'>
                <button className='rounded-full bg-white text-red-primary w-fit lg:px-44 px-12 py-2 cursor-pointer mt-5 bodyTextBold text-[22px]' onClick={async()=>{
                    await handlePostData({
                        data: postData,
                        setSuggestions: setSuggestions,
                    });
                }}>
                    More Suggestions
                </button>
            </div>
        </div>
    );
};

export default SuggestionsComponent;
