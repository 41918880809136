import { FC } from 'react'

interface RateUsButtionProps {
    onClick:()=>void;
}

const RateUsButtion: FC<RateUsButtionProps> = ({onClick}) => {
  return <div onClick={onClick} className='rounded-full bg-red-primary text-white w-fit px-7 py-2 cursor-pointer mt-5 bodyTextBold text-[22px]'>Rate Us</div>
}

export default RateUsButtion