import React, { useState } from 'react';

export type GenreType = {
  name: string;
  checked: boolean;
};

interface GenreSelectionProps {
    genres: GenreType[];
    onChange: (genre: GenreType[]) => void;
}

const GenreSelection: React.FC<GenreSelectionProps> = ({genres, onChange}) => {
  

  const toggleGenre = (index: number) => {
    const newGenres = genres.map((genre, i) => {
      if (i === index) {
        return { ...genre, checked: !genre.checked };
      }
      return genre;
    });
    onChange(newGenres);
  };

  return (
    <div className="bg-transparent">
      <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">What genres are you interested in?</h2>
      <p className="text-gray-500 text-center mb-6">(you can select multiple)</p>
      <div className="grid lg:grid-cols-2 gap-y-5 xl:ml-20">
        {genres.map((genre, index) => (
          <div key={genre.name} className="flex items-center">
            <div
              className={`relative rounded-full w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2 ${
                genre.checked ?  'bg-white border-4 border-gray-400' : 'bg-white border-4 border-gray-400'
              }`}
              onClick={() => toggleGenre(index)}
            >
              {genre.checked && (
                <div
                  className="absolute rounded-full bg-orange-500 w-4 h-4"
                ></div>
              )}
            </div>
            <label className="text-gray-700 cursor-pointer bodyText text-[20px]" onClick={() => toggleGenre(index)}>
              {genre.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GenreSelection;
