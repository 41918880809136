import React, { useState } from 'react';
import { GenreType } from './GenreSelection';
import { title } from 'process';



interface EraSelectionProps {
    title: string;
    era: GenreType[];
    onChange: (era: GenreType[]) => void;
}

const EraSelection: React.FC<EraSelectionProps> = ({era, onChange,title}) => {
  

  const toggleGenre = (index: number) => {
    const newGenres = era.map((genre, i) => {
      if (i === index) {
        return { ...genre, checked: !genre.checked };
      }
      return genre;
    });
    onChange(newGenres);
  };

  return (
    <div className="bg-transparent">
      <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">{title}</h2>
      <p className="text-gray-500 text-center mb-6">(you can select multiple)</p>
      <div className="flex flex-col gap-y-6">
        {era.map((genre, index) => (
          <div key={genre.name} className="flex items-center">
            <div
              className={`relative rounded-full w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2 ${
                genre.checked ?  'bg-white border-4 border-gray-400' : 'bg-white border-4 border-gray-400'
              }`}
              onClick={() => toggleGenre(index)}
            >
              {genre.checked && (
                <div
                  className="absolute rounded-full bg-orange-500 w-4 h-4"
                ></div>
              )}
            </div>
            <label className="text-gray-700 cursor-pointer bodyText text-[20px]" onClick={() => toggleGenre(index)}>
              {genre.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EraSelection;
