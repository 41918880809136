import { FC} from 'react'

interface CardProps {
    nameOfMovie: string,
    imdbScore: number,
    posterUrl: string,
    tomatoMeterScore: any,
}

const Card: FC<CardProps> = ({nameOfMovie, imdbScore,posterUrl,tomatoMeterScore}) => {
  
  return <div className='bg-white p-10 rounded-2xl w-[341px] md:h-[500px] h-full flex  flex-col justify-between'>
    <img src={posterUrl} alt={nameOfMovie}  className='md:w-[300px] md:h-[170px] object-cover rounded-2xl object-center'/>
    <h2 className='heading flex justify-center mt-4 md:text-[22px] uppercase'>{nameOfMovie}</h2>
    <div className='flex items-center gap-x-5 bodyText md:text-[22px] text-[18px] mt-3'>
        <img src='https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/171_Imdb_logo_logos-512.png' className='md:w-[66px] md:h-[62px] w-[44px] h-[44px]' alt='IMDBLogo'/>
        <p className='md:text-[32px] text-[24px]'>~</p>
        <p className='text-black'>{imdbScore.toString()} / 10</p>
    </div>
    <div className='flex items-center gap-x-5 bodyText md:text-[22px] text-[18px] mt-3'>
        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Rotten_Tomatoes.svg/2019px-Rotten_Tomatoes.svg.png' className='md:w-[66px] md:h-[62px] w-[44px] h-[44px]' alt='IMDBLogo'/>
        <p className='md:text-[32px] text-[24px]'>~</p>
        <p>{tomatoMeterScore} %</p>
    </div>
  </div>
}

export default Card