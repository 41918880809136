import { FC } from 'react'
import backgroundImage from '../assets/bg.png'
import RateUsButtion from '../components/RateUsButtion'

interface ContactProps {
  setModalOpen: (isOpen: boolean) => void;
}

const Contact: FC<ContactProps> = ({ setModalOpen}) => {
  return <div className="bg-[#f1ecec] bg-cover bg-center w-full h-full flex flex-col lg:justify-start lg:items-start justify-center items-center p-20">
    <h1 className='text-[55px] text-black bodyTextBold flex lg:flex-col flex-row justify-center lg:justify-start lg:items-start items-center w-full text-center lg:text-start'>
      Get in Touch
    </h1>
    <p className='text-[22px] bodyText mt-10flex lg:flex-col flex-row justify-center lg:justify-start lg:items-start items-center text-center lg:text-start'>Have feedback or suggestions? We'd love to hear from you!</p>
    <p className='text-[22px] bodyText mt-10 flex lg:flex-col flex-row justify-center lg:justify-start lg:items-start items-center text-center lg:text-start'>binge.aiapp@gmail.com</p>
    <div className='mt-7'>
      <RateUsButtion onClick={() => { setModalOpen(true) }} />
    </div>
  </div>
}

export default Contact