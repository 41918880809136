import { FC } from 'react'

interface Step1Props {
    title: string;
    setFieldValue: (value: string) => void;    
}

const Step1: FC<Step1Props> = ({title, setFieldValue}) => {
  return <div className='flex flex-col justify-between items-center bg-[#f1ecec]'>
    <div className='xl:text-[28px] text-[20px] bodyText text-center'>{title}</div>
    <input title="FieldValue" className='border-b border-black w-full mt-44 outline-none px-1 bg-transparent'  onChange={(e)=>setFieldValue(e.target.value)}/>
  </div>
}

export default Step1;
