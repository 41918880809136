import React, { useEffect, useState, useCallback } from 'react';
import Card from '../components/Card';
import axios from 'axios';

interface Movie {
    adult: boolean;
    backdrop_path?: string;
    genre_ids: number[];
    id: number;
    original_language: string;
    original_title: string;
    overview: string;
    popularity: number;
    poster_path?: string;
    release_date: string;
    title: string;
    video: boolean;
    vote_average: number;
    vote_count: number;
}

// Date construction for the API request
const today = new Date();
const oneMonthAgo = new Date(new Date().setMonth(today.getMonth() - 1));

const formattedToday = today.toISOString().split('T')[0];
const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0];

const options = {
    method: 'GET',
    url: 'https://api.themoviedb.org/3/discover/movie',
    params: {
        include_adult: 'false',
        include_video: 'false',
        language: 'en-US',
        page: "1",
        'primary_release_date.gte': formattedOneMonthAgo,
        'primary_release_date.lte': formattedToday,
        sort_by: 'popularity.desc',
        api_key: process.env.REACT_APP_TMDB_API_KEY,
    },
    headers: {
        accept: 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_TMDB_API_TOKEN}`
    }
};

const LatestReleases = () => {
    const [movies, setMovies] = useState<Movie[]>([]);

    const getMovieDetails = useCallback(async () => {
        try {
            const response = await axios.request(options);
            if (!response.data.results) {
                console.log("No data received from API");
                return;
            }

            const filteredMovies = response.data.results.filter((movie: Movie) => movie.poster_path != null).slice(0, 3);
            setMovies(filteredMovies);
        } catch (error) {
            console.error('Failed to fetch movies:', error);
        }
    }, []);

    useEffect(() => {
        getMovieDetails();
    }, [getMovieDetails]);

    return (
        <div className='bg-red-primary flex flex-col items-center justify-center p-20 overflow-hidden lg:overflow-auto h-screen'>
            <div className='heading text-[42px] text-white'>Latest Releases</div>
            <div className='flex flex-col lg:flex-row lg:items-center lg:justify-center justify-start lg:gap-y-0 gap-y-10 gap-x-10 mt-20 overflow-auto w-full'>
                {movies.map(movie => (
                    <div key={movie.id} className='mb-10 lg:mb-0 flex justify-center w-full'>
                        <Card
                            imdbScore={(Math.floor(movie.vote_average))}
                            nameOfMovie={movie.title}
                            posterUrl={`https://image.tmdb.org/t/p/original${movie.poster_path}`}
                            tomatoMeterScore={(Math.floor(movie.popularity < 1000 ? movie.popularity / 10 : movie.popularity / 100)).toString()}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LatestReleases;
