import { FC } from 'react'
import { GiHamburgerMenu } from "react-icons/gi";
import landingPhoto from '../assets/landingPhoto.webp'
import GetStartedButton from '../components/GetStartedButton';
interface LandingProps {
    setModalOpen: (isOpen: boolean) => void;
}

const Landing: FC<LandingProps> = ({ setModalOpen}) => {
    return <div className='bg-[#f1ecec] p-20 md:block flex flex-col gap-y-0 lg:gap-y-0'>
        <div className='md:ml-0 -ml-6'>
            <GiHamburgerMenu className='text-5xl text-red-primary cursor-pointer' />
        </div>
        <div className='flex lg:justify-between justify-center items-center mt-10'>
            <div>
                <div className='text-[64px] heading'>Binge.ai</div>
                <div className='bodyText text-[22px] w-80'>The movie partner you didn’t know you need</div>
                <GetStartedButton onClick={()=>{setModalOpen(true)}}/>
            </div>
            <div>
                <img src={landingPhoto} alt='Hello' className='rounded-2xl  xl:h-[614px] lg:block hidden' />
            </div>
        </div>
    </div>
}

export default Landing