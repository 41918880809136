import React, { useEffect, useState } from 'react';
import './App.css';
import Landing from './pages/Landing';
import About from './pages/About';
import LatestReleases from './pages/LatestReleases';
import ImageModal from './components/ImageModal';
import bg from './assets/bg.png';
import Contact from './pages/Contact';
import RateUsModal from './components/RateUsModal';
import LoadingScreen from './pages/LoadingScreen';
import AdsenseComponent from './components/AdsenseComponent';
// import { Adsense } from '@ctrl/react-adsense'
function App() {
  const [modalOpen, setModalOpen] = useState(false)
  const [steps, setSteps] = useState(1);
  const [rateUsModalOpen, setRateUsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //   useEffect(() => {
  //     setIsLoading(true);
  //     setTimeout(() => {
  //         setIsLoading(false);
  //     }, 1000);  // Timeout duration set to 3000 milliseconds (3 seconds)
  // }, []);


  if (isLoading) return <LoadingScreen />;
  return (
    <div className='w-full min-h-screen h-full'>
      <section className='h-full '>
        <Landing setModalOpen={setModalOpen} />
      </section>
      <section className='h-full'>
        <About setModalOpen={setModalOpen} />
      </section>
      <section className='h-full mt-10'>
        <LatestReleases />
      </section>
      <section className='h-full'>
        <Contact setModalOpen={setRateUsModalOpen} />
        <div className=''>
          <AdsenseComponent/>
        </div>
      </section>
      <ImageModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        backgroundImage={bg}
        setSteps={setSteps}
        steps={steps}
      />
      <RateUsModal isOpen={rateUsModalOpen} onClose={() => setRateUsModalOpen(false)} />

    </div>
  );
}

export default App;
